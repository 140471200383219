import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby'
import { Link, IconButton, useColorMode, Box } from 'theme-ui'

import Section from '@components/Section'
import Logo from '@components/Logo'

import Icons from '@icons'
import mediaqueries from '@styles/media'
import {
  copyToClipboard,
  getWindowDimensions,
  getBreakpointFromTheme
} from '@utils'

import { useResponsiveValue } from '@theme-ui/match-media'

const siteQuery = graphql`
  {
    sitePlugin(name: { eq: "gatsby-theme-didactic-adventure" }) {
      pluginOptions {
        rootPath
        basePath
      }
    }
  }
`

const DarkModeToggle: React.FC<{}> = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === 'dark'

  function toggleColorMode (event): void {
    event.preventDefault()
    setColorMode(isDark ? 'light' : 'dark')
  }

  return (
    <IconWrapper
      isDark={isDark}
      onClick={toggleColorMode}
      data-a11y='false'
      aria-label={isDark ? 'Activate light mode' : 'Activate dark mode'}
      title={isDark ? 'Activate light mode' : 'Activate dark mode'}
    >
      <MoonOrSun isDark={isDark} />
      <MoonMask isDark={isDark} />
    </IconWrapper>
  )
}

const SharePageButton: React.FC<{}> = () => {
  const [hasCopied, setHasCopied] = useState<boolean>(false)
  const [colorMode] = useColorMode()
  const isDark = colorMode === 'dark'
  const fill = isDark ? '#fff' : '#000'

  function copyToClipboardOnClick (): void {
    if (hasCopied) return

    copyToClipboard(window.location.href)
    setHasCopied(true)

    setTimeout(() => {
      setHasCopied(false)
    }, 1000)
  }

  return (
    <IconWrapper
      isDark={isDark}
      onClick={copyToClipboardOnClick}
      data-a11y='false'
      aria-label='Copy URL to clipboard'
      title='Copy URL to clipboard'
    >
      <Icons.Link fill={fill} />
      <ToolTip isDark={isDark} hasCopied={hasCopied}>
        Copied
      </ToolTip>
    </IconWrapper>
  )
}

const NavigationHeader: React.FC<{}> = () => {
  const [previousPath, setPreviousPath] = useState<string>('/')
  const { sitePlugin } = useStaticQuery(siteQuery)

  const [colorMode] = useColorMode()
  const fill = colorMode === 'dark' ? '#fff' : '#000'
  const { rootPath, basePath } = sitePlugin.pluginOptions
  const [menuStatus, setMenuStatus] = useState<boolean>(false)
  const showOnMobile = useResponsiveValue(
    [true, true, true, true, true, true, false],
    {
      defaultIndex: 7
    }
  )

  const sx = getStyles({ menuStatus })
  useEffect(() => {
    const { width } = getWindowDimensions()
    const phablet = getBreakpointFromTheme('phablet')

    const prev = localStorage.getItem('previousPath')
    const previousPathWasHomepage =
      prev === (rootPath || basePath) || (prev && prev.includes('/page/'))
    const currentPathIsHomepage =
      location.pathname === (rootPath || basePath) ||
      location.pathname.includes('/page/')

    setPreviousPath(prev)
  }, [])

  return (
    <Section>
      <NavContainer>
        <IconButton
          sx={{
            color: 'primary',
            zIndex: '101',
            '@media screen and (max-width: 52.5rem)': {
              display: 'flex'
            },
            '@media screen and (min-width: 52.5rem)': {
              display: 'none'
            }
          }}
          onClick={() => setMenuStatus(!menuStatus)}
        >
          {menuStatus ? <Icons.Close /> : <Icons.Menu />}
        </IconButton>
        <Link
          data-a11y='false'
          title='Navigate back to the homepage'
          aria-label='Navigate back to the homepage'
          to='/'
          as={GatsbyLink}
          sx={{
            width: '7rem',
            position: 'relative',
            fill: 'primary',
            ...afterBorder,
            '@media screen and (max-width: 52.5rem)': {
              display: 'none'
            },
            '@media screen and (min-width: 52.5rem)': {
              display: 'block'
            }
          }}
        >
          <Logo />
        </Link>
        <Box
          sx={{
            '@media screen and (max-width: 52.5rem)': {
              ...sx.mobileMenu
            },
            '@media screen and (min-width: 52.5rem)': {
              ...sx.desktopMenu
            }
          }}
         >
          <PageNavigationItem data-a11y='false' to='/'>
            Blog
          </PageNavigationItem>
          <PageNavigationItem data-a11y='false' to='/destinations'>
            Destinations
          </PageNavigationItem>
          <PageNavigationItem data-a11y='false' to='/about'>
            About
          </PageNavigationItem>
        </Box>
        <NavControls>
          <SharePageButton />
          <DarkModeToggle />
        </NavControls>
      </NavContainer>
    </Section>
  )
}

export default NavigationHeader

const getStyles = ({ menuStatus }): void => ({
  mobileMenu: {
    display: [menuStatus ? 'flex' : 'none'],
    top: '0',
    left: '0',
    position: 'fixed',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'background',
    zIndex: '100'
  },
  desktopMenu: {
    display: 'flex',
    alignItems: 'center'
  }
})

const afterBorder = {
  ':focus-visible::after': {
    content: "''",
    position: 'absolute',
    left: '-10%',
    top: '-30%',
    width: '120%',
    height: '160%',
    border: '2px solid',
    borderColor: 'accent',
    borderRadius: '5px'

  }
}

const PageNavigationItem = styled(GatsbyLink)`
  padding: 10px 30px 10px 30px;
  color: ${(p) => p.theme.colors.primary};
  font-weight: bold;
  font-size: 20px;
  position: relative;

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -10%;
    top: -30%;
    width: 120%;
    height: 160%;
    border: 2px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

`

const NavContainer = styled.div`
  position: relative;
  z-index: 100;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;

  ${mediaqueries.desktop_medium`
    padding-top: 50px;
  `};

  @media screen and (max-height: 800px) {
    padding-top: 50px;
  }
`

const NavControls = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${mediaqueries.phablet`
    right: -5px;
  `}
`

const ToolTip = styled.div<{ isDark: boolean, hasCopied: boolean }>`
  position: absolute;
  padding: 4px 13px;
  background: ${(p) => (p.isDark ? '#000' : 'rgba(0,0,0,0.1)')};
  color: ${(p) => (p.isDark ? '#fff' : '#000')};
  border-radius: 5px;
  font-size: 14px;
  top: -35px;
  opacity: ${(p) => (p.hasCopied ? 1 : 0)};
  transform: ${(p) => (p.hasCopied ? 'translateY(-3px)' : 'none')};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${(p) => (p.isDark ? '#000' : 'rgba(0,0,0,0.1)')};
  }
`

const IconWrapper = styled.button<{ isDark: boolean }>`
  opacity: 0.5;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  margin-left: 30px;

  &:hover {
    opacity: 1;
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.tablet`
    display: inline-flex;
    transform: scale(0.708);
    margin-left: 10px;


    &:hover {
      opacity: 0.5;
    }
  `}
`

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
const MoonOrSun = styled.div<{ isDark: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${(p) => (p.isDark ? '4px' : '2px')} solid
    ${(p) => p.theme.colors.primary};
  background: ${(p) => p.theme.colors.primary};
  transform: scale(${(p) => (p.isDark ? 0.55 : 1)});
  transition: all 0.45s ease;
  overflow: ${(p) => (p.isDark ? 'visible' : 'hidden')};

  &::before {
    content: "";
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: 2px solid ${(p) => p.theme.colors.primary};
    border-radius: 50%;
    transform: translate(${(p) => (p.isDark ? '14px, -14px' : '0, 0')});
    opacity: ${(p) => (p.isDark ? 0 : 1)};
    transition: transform 0.45s ease;
  }

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 ${(p) => p.theme.colors.primary},
      0 23px 0 ${(p) => p.theme.colors.primary},
      23px 0 0 ${(p) => p.theme.colors.primary},
      -23px 0 0 ${(p) => p.theme.colors.primary},
      15px 15px 0 ${(p) => p.theme.colors.primary},
      -15px 15px 0 ${(p) => p.theme.colors.primary},
      15px -15px 0 ${(p) => p.theme.colors.primary},
      -15px -15px 0 ${(p) => p.theme.colors.primary};
    transform: scale(${(p) => (p.isDark ? 1 : 0)});
    transition: all 0.35s ease;

    ${(p) => mediaqueries.tablet`
      transform: scale(${p.isDark ? 0.92 : 0});
    `}
  }
`

const MoonMask = styled.div<{ isDark: boolean }>`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  background: ${(p) => p.theme.colors.background};
  transform: translate(${(p) => (p.isDark ? '14px, -14px' : '0, 0')});
  opacity: ${(p) => (p.isDark ? 0 : 1)};
  transition: ${(p) => p.theme.colorModeTransition}, transform 0.45s ease;
`
