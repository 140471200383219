import React from 'react'
import { Box } from 'theme-ui'

import { Icon } from '@types'

const Logo: Icon = ({ fill = 'white' }) => {
  return (
    <Box>
      <svg viewBox='60.765 116.7 815 266.6' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M 5960 7726 C 5952 7728 2361 5115 2364 5113 C 2367 5110 5793 7250 5795 7250 C 5796 7250 5776 7206 5750 7153 C 5725 7099 5672 6988 5634 6906 C 5566 6760 5563 6754 5435 6612 C 5364 6532 5250 6402 5250 6402 C 5250 6402 5430 6138 5430 6129 C 5430 6115 4561 5426 4563 5424 C 4565 5422 5714 5629 5718 5626 C 5724 5620 5320 5069 5320 5066 C 5320 5063 5901 5060 5901 5060 C 5901 5060 8206 6330 8222 6330 C 8230 6330 8258 6279 8100 6055 L 7931 5815 L 8118 5812 C 8313 5809 8320 5808 8320 5763 C 8320 5732 8232 5671 7962 5520 C 7791 5424 7734 5387 7732 5371 C 7728 5347 8320 5570 8329 5570 C 8335 5570 8321 5462 8199 5279 C 8122 5163 8061 5066 8063 5064 C 8065 5062 10514 5065 10514 5065 C 10514 5065 8327 6590 8317 6590 C 8307 6590 7799 6440 7793 6440 C 7780 6440 5968 7724 5960 7726 Z'
          transform='matrix(0.1, 0, 0, -0.1, -175.635117, 889.300537)'
        />
      </svg>
    </Box>
  )
}

export default Logo
